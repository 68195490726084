import React, { Component } from 'react';

import {getCookie,setCookie} from '../../utils/cookies';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import ActionButton from "../UI/Buttons/ActionButton";

import classes from './CookieConsent.module.scss';



class CookieConsent extends Component {


    // constructor(props) {
    //     super(props);
    // }



    state = {
        isVisible:false,
        opened:false
    };


    componentDidMount(){
        if( !getCookie("ami-cookie-privacy") ){
            this.setState({
                isVisible:true
            })
        }
    }

    toggle = () => {

        this.setState((prevState)=>{
            return {
                opened:!prevState.opened
            }
        })
    };


    accept = () => {
        setCookie("ami-cookie-privacy", 1, 365);

        this.setState({
            isVisible:false
        })
    };


    render() {

        if(!this.state.isVisible){
            return null;
        }

        let buttonLabel = null;
        let clazzContentArea = [];
        let clazzReadMoreImg = [];
        if(this.state.opened){
            clazzContentArea.push(classes.opened);
            clazzReadMoreImg.push(classes.flipVertical);
            buttonLabel = "READ LESS";
        } else {
            clazzContentArea.push(classes.closed);
            buttonLabel = "READ MORE";
        }

        return (

            <div className={classes.cookieContent} >
                <Container className={classes.container}>
                    <div className={classes.infoBox}>
                        <div className={classes.contentArea+" "+clazzContentArea.join(' ')}>
                            <p>
                                This website uses cookies and other technologies to personalize content and to show you more personalized ads (for example, Google Ads and Facebook) on this and other websites, as well as provide you with social media features on this website (such as, Facebook, Twitter, LinkedIn). By clicking “Accept” you understand that you are directing Allergan Aesthetics, an AbbVie company to disclose your personal information, including internet tracking data (like cookies and the IP address you used to access this website), to these third parties for the purposes stated above.
                            </p>
                            <p>
                                You can still use this website if you do not click “Accept,” but your experience may be different.
                            </p>
                            <p>
                                For more information, including a list of the categories of third parties to whom we disclose your personal information, please refer to our <a className="link-text" target="_blank" rel="noreferrer" href="https://www.allergan.com/privacy-and-terms/ccpa" >California Privacy Policy</a> or for additional options please check your browser’s settings.
                            </p>
                        </div>

                        <div className={classes.expandCtlContainer}>
                            <ActionButton onClick={this.toggle} className={""}>
                                {buttonLabel}
                                <img src={require("../../assets/images/icons/ico-chevron-reg-up.svg").default}
                                     width={20}
                                     height={20}
                                     className={clazzReadMoreImg.join(' ')}
                                     alt=""/>
                            </ActionButton>
                        </div>
                    </div>

                    <div className={classes.submitSection}>
                        <Button onClick={this.accept} style={{minHeight:"auto", textAlign:"center"}}>Accept</Button>
                    </div>

                </Container>
            </div>
        );
    }
}

export default CookieConsent;


